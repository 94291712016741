/* ==========================================================================
  Compte client
  ========================================================================== */

.compte-client_nav {
  margin-bottom: 3%;
}

.compte-client_nav_list {
  @extend .list-inline;
  @extend .text-center;
}
